
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { EmbeddedContent } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

@Component
export default class Embeddable extends Vue {
  @Prop({ required: true })
  private readonly embeddable!: EmbeddedContent;

  private readonly origin: string = window.location.origin;

  mounted() {
    setTimeout(() => {
      console.log(this.embeddable);
    }, 10000);
  }
}
