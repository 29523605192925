import { ResizableImage } from 'client-website-ts-library/types/ResizableImage';
import { EmbeddedContent } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import { Utils } from 'client-website-ts-library/util/Utils';
import { ImageSourceSet } from 'client-website-ts-library/types';

enum ItemCyclerSlideType {
  Embeddable = 'embeddable',
  Image = 'image',
  LazyImage = 'lazyimage'
}

class ItemCyclerSlide {
  public type: ItemCyclerSlideType;

  public readonly id: string = Utils.GetGuid();

  constructor(type: ItemCyclerSlideType) {
    this.type = type;
  }
}

class ItemCyclerImageSlide extends ItemCyclerSlide {
  public image: string | ImageSourceSet;

  constructor(image: string | ImageSourceSet) {
    super(ItemCyclerSlideType.Image);

    this.image = image;
  }
}

class ItemCyclerReiszableImageSlide extends ItemCyclerSlide {
  public image: ResizableImage;

  constructor(image: ResizableImage) {
    super(ItemCyclerSlideType.LazyImage);

    this.image = image;
  }
}

class ItemCyclerEmbeddableSlide extends ItemCyclerSlide {
  public embeddable: EmbeddedContent;

  constructor(embeddable: EmbeddedContent) {
    super(ItemCyclerSlideType.Embeddable);

    this.embeddable = embeddable;
  }
}

export { ItemCyclerSlide, ItemCyclerSlideType, ItemCyclerImageSlide, ItemCyclerEmbeddableSlide, ItemCyclerReiszableImageSlide };
